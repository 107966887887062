import _defineProperty from "/builds/u5yGSXwH/6/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/builds/u5yGSXwH/6/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { sessionStorage } from 'react-storage';
import { EventValues } from '@sial/common-utils';
import { isServer } from '@src/utils/isServer';
import { useCookies, AppCookies } from '@src/utils/cookies';
import { searchResultsRoute } from '@src/routes';
import { sendOrderConfirmationEvent } from '@utils/analytics/cartAndCheckoutEvents';
import { orderConfirmationTrack } from '@utils/tealiumAnalytics';
import { ProductSearchType, determineQueryFocus, getValidSearch, formatCasNumberSearchTerm } from '@utils/searchUtils';
export var buildProductAliasPayload = function buildProductAliasPayload(_ref) {
  var _term;

  var action = _ref.action,
      detail = _ref.detail,
      focus = _ref.focus,
      productId = _ref.productId,
      productBrand = _ref.productBrand;
  var type;
  var term;

  switch (action) {
    case 'ec number':
      type = ProductSearchType.EgecNumber;
      break;

    case 'mdl number':
      type = ProductSearchType.MdlNumber;
      break;

    case 'cas number':
      type = ProductSearchType.CasNumber;
      term = formatCasNumberSearchTerm(detail);
      break;

    default:
  }

  return {
    action: action + ' click',
    detail: detail,
    component: 'body',
    elementType: 'link',
    elementText: detail,
    linkUrl: "".concat(searchResultsRoute.searchResults(term || detail || undefined)).concat(getValidSearch({
      term: ((_term = term) === null || _term === void 0 ? void 0 : _term.toLowerCase()) || (detail === null || detail === void 0 ? void 0 : detail.toLowerCase()),
      focus: determineQueryFocus(focus),
      type: type
    })),
    productId: productId,
    productBrand: productBrand
  };
};
/*
  BUILDS PROMO OBJECTS FOR CAROUSELS

  Could be used elsewhere for product data structures that use the gaProductCode alongside product data.
  Carousels is the original primary purpose.

  Can build both GA4 promo objects and legacy UA promo objects.
*/

export var buildGaPromoObjects = function buildGaPromoObjects(rawGaProductCode, pickedProduct) {
  var _rawGaProductCode$toL, _pickedProduct$produc, _pickedProduct$produc2, _pickedProduct$brand, _pickedProduct$brand$;

  var position = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var listNameOverride = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var gaProductCode = (rawGaProductCode === null || rawGaProductCode === void 0 ? void 0 : (_rawGaProductCode$toL = rawGaProductCode.toLowerCase()) === null || _rawGaProductCode$toL === void 0 ? void 0 : _rawGaProductCode$toL.split('|')) || [];
  var pageParent = sessionStorage.getItem('DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT');
  var legacyPromo = {
    id: (gaProductCode === null || gaProductCode === void 0 ? void 0 : gaProductCode[0]) || EventValues.NotAvailable,
    name: (gaProductCode === null || gaProductCode === void 0 ? void 0 : gaProductCode[1]) || EventValues.NotAvailable,
    creative: (gaProductCode === null || gaProductCode === void 0 ? void 0 : gaProductCode[2]) || EventValues.NotAvailable
  };
  var ga4PromoObj = {
    creative_name: gaProductCode[2] || EventValues.NotAvailable,
    promotion_id: gaProductCode[0] || EventValues.NotAvailable,
    promotion_name: gaProductCode[1] || EventValues.NotAvailable,
    index: position,
    item_id: (pickedProduct === null || pickedProduct === void 0 ? void 0 : (_pickedProduct$produc = pickedProduct.productKey) === null || _pickedProduct$produc === void 0 ? void 0 : _pickedProduct$produc.toLowerCase()) || (pickedProduct === null || pickedProduct === void 0 ? void 0 : (_pickedProduct$produc2 = pickedProduct.productNumber) === null || _pickedProduct$produc2 === void 0 ? void 0 : _pickedProduct$produc2.toLowerCase()) || EventValues.NotAvailable,
    item_brand: (pickedProduct === null || pickedProduct === void 0 ? void 0 : (_pickedProduct$brand = pickedProduct.brand) === null || _pickedProduct$brand === void 0 ? void 0 : (_pickedProduct$brand$ = _pickedProduct$brand.key) === null || _pickedProduct$brand$ === void 0 ? void 0 : _pickedProduct$brand$.toLowerCase()) || EventValues.NotAvailable,
    item_list_id: listNameOverride || (pageParent === null || pageParent === void 0 ? void 0 : pageParent.detail),
    item_list_name: listNameOverride || (pageParent === null || pageParent === void 0 ? void 0 : pageParent.detail)
  };
  return {
    promo: legacyPromo,
    ga4PromoObj: ga4PromoObj
  };
};
export var calculateDiscountPercentage = function calculateDiscountPercentage(listPrice, discountedPrice) {
  return (listPrice !== null && listPrice !== void 0 ? listPrice : 0) > (discountedPrice !== null && discountedPrice !== void 0 ? discountedPrice : 0) ? Math.round(((listPrice !== null && listPrice !== void 0 ? listPrice : 0) - (discountedPrice !== null && discountedPrice !== void 0 ? discountedPrice : 0)) / (listPrice !== null && listPrice !== void 0 ? listPrice : 1) * 100) : undefined;
};
/*
  DEBOUNCE TRANSACTION ID

  There are cases where customers are bookrmarking or revisiting their order confirmations.
  Reloading that page however triggers the order confirmation analytics event every time.

  This function creates a cookie to log the last 100 orders viewed by a customer on their machine.
  Only sending the analytics confirmation event if the order has never been viewed before.
  Which should greatly reduce the duplicate sending of this event.

  A slight point of confusion here is Analytics calls it the "transaction_id" while we use "orderId" as our internal UUID for orders.
  So they become a bit interchangable in this case.
*/

export var useSendOrderConfirmationEventOnce = function useSendOrderConfirmationEventOnce(_ref2) {
  var _data$orderDetail;

  var data = _ref2.data,
      cartType = _ref2.cartType,
      userType = _ref2.userType;

  var _useCookies = useCookies(),
      _useCookies2 = _slicedToArray(_useCookies, 2),
      cookies = _useCookies2[0],
      setCookies = _useCookies2[1];

  var orderData = data === null || data === void 0 ? void 0 : (_data$orderDetail = data.orderDetail) === null || _data$orderDetail === void 0 ? void 0 : _data$orderDetail.order;
  var transactionId = (orderData === null || orderData === void 0 ? void 0 : orderData.orderNumber) || (orderData === null || orderData === void 0 ? void 0 : orderData.orderId); // The 'data' can be in a state where it isn't loaded or defined yet.
  // Additionally these cookies and events only send client side.
  // So if we don't have a transaction ID or the hook is running server side just kick out early.

  if (!transactionId || isServer()) {
    return;
  }

  var seenOrders = (cookies === null || cookies === void 0 ? void 0 : cookies[AppCookies.MMViewedTransactionIds]) || [];

  if (!seenOrders.includes(transactionId)) {
    seenOrders.push(transactionId);

    if (seenOrders.length > 100) {
      seenOrders.shift();
    }

    sendOrderConfirmationEvent(_objectSpread(_objectSpread({}, orderData), {}, {
      cartType: cartType
    }));
    orderConfirmationTrack(data, userType);
    setCookies(AppCookies.MMViewedTransactionIds, seenOrders);
  }
};