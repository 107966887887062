import getConfig from 'next/config';
import { useEffect } from 'react';
import { isServer } from '@src/utils/isServer';
export function useOptimizely() {
  var _window;

  var _getConfig = getConfig(),
      optimizelyId = _getConfig.publicRuntimeConfig.optimizelyId;

  var hasOptimizelyObj = !isServer() && ((_window = window) === null || _window === void 0 ? void 0 : _window.optimizely);

  var activateOptlyPage = function activateOptlyPage(optlyPage) {
    var _window$optimizely, _window$optimizely2;

    (_window$optimizely = window.optimizely) === null || _window$optimizely === void 0 ? void 0 : _window$optimizely.push({
      type: 'page',
      pageName: optlyPage.apiName,
      isActive: false
    });
    (_window$optimizely2 = window.optimizely) === null || _window$optimizely2 === void 0 ? void 0 : _window$optimizely2.push({
      type: 'page',
      pageName: optlyPage.apiName
    });
  };

  useEffect(function () {
    if (!optimizelyId || !window.optimizely) return;

    try {
      var _window2, _window2$optimizely, _window2$optimizely$g;

      var optlyPages = ((_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$optimizely = _window2.optimizely) === null || _window2$optimizely === void 0 ? void 0 : (_window2$optimizely$g = _window2$optimizely.get('data')) === null || _window2$optimizely$g === void 0 ? void 0 : _window2$optimizely$g.pages) || {};

      for (var page in optlyPages) {
        activateOptlyPage(optlyPages[page]);
      }
    } catch (e) {
      console.error('Problem initializing Optimizely pages.');
      console.error(e);
    }
  }, [hasOptimizelyObj]);
}
export default useOptimizely;